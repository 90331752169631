export enum EntityType {
    Order,
    Featured,
    Merchant,
    Mastercard,
    DigitalMenuItem,
    PhysicalMenu,
    PostCheckoutLink,
    PostCheckoutMessage,
    QrCodeSession,
    Charge,
    Invoice,
    OrderField,
}

const keyName = (entityType: EntityType): string => {
    switch(entityType)
    {
        case EntityType.Order: return "orders";
        case EntityType.Featured: return "featureds";
        case EntityType.Merchant: return "merchants";
        case EntityType.Mastercard: return "mastercards";
        case EntityType.DigitalMenuItem: return "digitalmenuitems";
        case EntityType.PhysicalMenu: return "physicalmenus";
        case EntityType.PostCheckoutLink: return "postcheckoutlinks";
        case EntityType.PostCheckoutMessage: return "postcheckoutmessages";
        case EntityType.QrCodeSession: return "qrcodesessions";
        case EntityType.Charge: return "charges";
        case EntityType.Invoice: return "invoices";
        case EntityType.OrderField: return "orderfields";
    }
}
export const getKey = (entityType: EntityType, id?: string): string => {
    const keys = [keyName(entityType)];
    if(id != undefined) {
        keys.push(id);
    }
    return keys.join("/");
}

export const getIdFromKey = (entityType: EntityType, key: string): string => {
    const aux = key.split("/");
    if(aux.length != 2) {
        throw Error();
    }
    if(aux[0] != keyName(entityType)) {
        throw Error();
    }
    return aux[1];
}
import { createSlice } from "@reduxjs/toolkit";
import { MenuType } from "./services/api/contracts/models/menuType";
import { Features } from "./services/api/contracts/models/Features/features";
import { ChargeMethod } from "./services/api/contracts/models/ChargeMethod";

export interface Menuitem {
    readonly menuItemId: string;
    readonly name: string;
    readonly description: string;
    readonly stock: boolean;
    readonly teaserPhoto: string;
    readonly price: number;
    readonly priceType: string;
}

export interface DigitalMenu {
    readonly category: string;
    readonly categoryList: Menuitem[]
}

export interface MerchantState {
    readonly qrCodeId: string,
    readonly logo: string,
    readonly eatsMerchantName: string,
    readonly merchantId: string,
    readonly menuType: MenuType | null,
    readonly availablePaymentMethods: ChargeMethod[],
    readonly features: Features,
    readonly surchargeFeesMayApply: boolean,
    readonly inactive?: boolean,
}

const initialState: MerchantState = {
    qrCodeId: "",
    logo: "",
    eatsMerchantName: "",
    merchantId: "",
    menuType: MenuType.Digital,
    availablePaymentMethods: [],
    features: { 
        payAtTheTable: {
            isActive: true,
            allowsInvoiceDownloads: false,
            freePayment: true, 
            itemSelectionPayment: true, 
            splitBillPayment: true, 
            allowsAddingItemsToSession: true,
            allowsRemovingItemsFromSession: true,
            allowsIgnoreBill: true,
            enforceTip: false,
        },
        ordering: {
            isActive: true,
            allowsPostPaidOrdering: false,
            allowsPrePaidOrdering: true,
            invoiceIsDownloadable: false,
            allowsTracking: true,
            mandatoryUserEmailForTakeawayPayment: true,
            allowScheduling: false,
            enforceTip: false,
            minimumPrePaidOrderAmount: 0,
        },
        freePayments: {
            isActive: false,
            isTipOnly: false,
        },
        showPaymentNote: false,
        allowsSessions: false,
        physicalKiosk: false,
    },
    surchargeFeesMayApply: true,
    inactive: false,
};

export const merchantSlice = createSlice({
    name: "merchant",
    initialState,
    reducers: {
        setInactive(state, action) {
            state.inactive = action.payload;
        },
        
        setLogo(state, action) {
            state.logo = action.payload;
        },

        setQrCodeId(state, action) {
            state.qrCodeId = action.payload;
        },

        setMenuType(state, action) {
            state.menuType = action.payload;
        },

        setMerchantName(state, action) {
            state.eatsMerchantName = action.payload;
        },

        setAvailalePaymentMethods(state, action) {
            state.availablePaymentMethods = action.payload;
        },

        setFeatures(state, action) {
            state.features = action.payload;
        },

        setSurchageFeesMayApply(state, action) {
            state.surchargeFeesMayApply = action.payload;
        },

        setMerchantId(state, action) {
            state.merchantId = action.payload;
        },
    }
});

export const {
    setInactive,
    setLogo,
    setQrCodeId,
    setMenuType,
    setMerchantName,
    setAvailalePaymentMethods,
    setFeatures,
    setSurchageFeesMayApply,
    setMerchantId,
} = merchantSlice.actions;

export default merchantSlice.reducer;

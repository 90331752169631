import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { QueryResult } from "../query/QueryResult";
import { DigitalMenuItem } from "../../services/api/contracts/models/digitalMenuItem";
import { useAppSelector } from "../../hooks";
import { IResponse, useQueryable } from "../query/useQueryable";
import { EntityType } from "../query/EntityType";

interface Request {
    readonly categoryId?: string;
    readonly itemIds?: string[];
    readonly languageIso: string;
    readonly ignoreCalendarAvailability?: boolean;
    readonly atDate?: Date;
}
export const useDigitalMenuItemsQuery = (request: Request | undefined): QueryResult<DigitalMenuItem[]> => {
    const api = useWeb10API();

    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const query = useQueryable<Request, DigitalMenuItem, IResponse<DigitalMenuItem>>({
        queryName: "useDigitalMenuItemsQuery",
        entityType: EntityType.DigitalMenuItem,
        getId: (item: DigitalMenuItem) => item.id,
        request: !merchantId ? undefined : request,
        query: async request => {
            const response = await api.menu.digital.GetDigitalMenu({
                ...request,
                merchantId: merchantId,
            });
            return {
                data: response,
            }
        },
        getIdsFilter: (r) => r.itemIds,
        refreshOnAnyUpdate: request?.itemIds == undefined,
    })
    
    return query;
}
import { Skeleton } from "@material-ui/lab";
import { originalUnitPrice, roundDecimalsUp } from "../../app/services/calculations";
import { toFormattedAmount } from "../../app/services/format";
import { BaseReceiptLine, ReceiptLine } from "./ReceiptLine";
import { InfoIcon } from "../svgs/InfoIcon";
import { useDeGrazieTheme } from "../../app/hooks/theme/useDeGrazieTheme";
import { Chip } from "@material-ui/core";

type ReceiptItemLineProps = {
    readonly item: BaseReceiptLine | undefined;
    readonly isSubItem: boolean;
}
const ReceiptItemLine: React.FC<ReceiptItemLineProps> = ({ 
    item,
    isSubItem
}) => {
    const theme = useDeGrazieTheme();

    const isStroke = item != undefined && item.isStroke; 
    const itemFractionPrice = item == undefined ? undefined : (item.quantity ?? 1) * item.amount;
    const formattedQuantity = item == undefined ? undefined : Number((item.quantity ?? 1).toFixed(2)).toString().replace(".", ",");
    const unitPrice = item == undefined ? 1 : (item.discount ? originalUnitPrice(item.amount, item.discount) : item.amount);

    return (
        <div className={`table-item ${isStroke ? "is-paid" : ""}`}>
            <p className="quantity">
                {
                    formattedQuantity != undefined
                    ?
                        !isSubItem && item?.quantity != undefined && formattedQuantity.toString()
                    :
                        <Skeleton variant="text" animation="wave" height="1.5rem" width="70%"/>
                }
            </p>
            <p>
                {
                    item != undefined 
                    ?
                    <>
                        {item.name}
                        {
                            item.info != undefined &&
                            <>
                                &nbsp;&nbsp;
                                <Chip label={<p>{item.info}</p>} variant="outlined" size="small" avatar={<>&nbsp;<InfoIcon color={theme.primaryColor.hex} /></>}/>
                            </>
                        }
                    </>
                    :
                        <Skeleton variant="text" animation="wave" height="1.5rem" width="70%"/>
                }
            </p>
            {
                item != undefined && (item.discount || (item.quantity != 1 && (item.quantity ?? 1) > 0 && unitPrice != itemFractionPrice))
                ?
                    <p className="unit price">{toFormattedAmount(roundDecimalsUp(unitPrice))} €</p>
                :
                    <p className="unit price"></p>
            }
            {
                itemFractionPrice == undefined
                ?
                    <Skeleton variant="text" animation="wave" height="1.5rem" width="70%"/>
                :
                (
                    itemFractionPrice != 0 &&
                    <p className="fraction price">
                        {toFormattedAmount(itemFractionPrice)} €
                    </p>
                )
            }
        </div>
    );
}

type ReceiptLineProps = {
    readonly item: ReceiptLine | undefined;
}
const ReceiptItem: React.FC<ReceiptLineProps> = ({ item }) => {
    return <>
        <ReceiptItemLine item={item} isSubItem={false}/>
        {
            item != undefined &&
            item.subItems != undefined &&
            item.subItems.map((s, index) => {
                const result = [];
                for(let i = 0; i < (s.quantity ?? 1); ++i) {
                    const aux = {...s, quantity: item.quantity, isStroke: item.isStroke }
                    result.push(<ReceiptItemLine item={aux} isSubItem={true} key={`${index}-${i}`}/>);
                }
                return result;
            }).reduce((r, items) => [...r, ...items], [])
        }
    </>
};
export default ReceiptItem;
export interface OrderField {
    readonly id: string;
    readonly name: string;
    readonly isRequired: boolean;
    readonly type: OrderFieldType;
}

export enum OrderFieldType {
    Text = 0,
    LongText = 1,
    Check = 2,
    Number = 3,
}
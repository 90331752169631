import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { LOW_AMOUNT_PAYMENT, SECOND_TIER_TIP } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useCart } from "../../app/hooks/menus/order/useCart";
import { TipsOptions, TipsOptionsConfiguration } from "../../app/models/tipsOptions";
import { setPaymentDetails } from "../../app/paymentSlice";
import { useBrowserStorageService } from "../../app/hooks/useBrowserStorageService";
import { calculateTip, calculateUserTotal, roundDecimalsUp } from "../../app/services/calculations";
import { formatToCleanString, toFormattedPrice } from "../../app/services/format";
import { vatValidation } from "../../app/services/inputValidators";
import { PaymentDetails } from "../../app/types/paymentTypes";
import Alert from "../../components/Alerts/Alert";
import PayTotalCart from "./PayTotalCart";
import TipSection from "./TipSection/TipSection";
import { useAppNavigation } from "../../app/hooks/useAppNavigation";
import Dialog from "../../components/Shared/Dialog";
import { CloseIcon } from "../../components/svgs/CloseIcon";
import { Tooltip } from "@material-ui/core";
import { useFeesQuery } from "../../app/hooks/fees/useFeesQuery";
import { useMastercard } from "../../app/hooks/mastercard/useMastercard";
import { ChargeMethod } from "../../app/services/api/contracts/models/ChargeMethod";
import { Page } from "../../layout/Page";
import { ButtonsSection } from "../../layout/ButtonsSection";
import { useQa } from "../../app/hooks/useQa";

const OrderCheckoutPage = () => {
    const browserStorageService = useBrowserStorageService();
    const { t } = useTranslation();
    const { qaTag } = useQa();
    const dispatch = useAppDispatch();
    const appNavigation = useAppNavigation();

    const userAuth = useAppSelector(state => state.user.isAuth);
    const fees = useFeesQuery();
    const mastercard = useMastercard();
    
    const cartSession = useCart();
    const userVat = useAppSelector(state => state.user.vatNumber);
    const userEmail = useAppSelector(state => state.user.userEmail);
    const paymentDetails = useAppSelector(state => state.payment.paymentDetails);
    const paymentDivision = useAppSelector(state => state.payment.paymentDivision);
    const features = useAppSelector(state => state.merchant.features);
    
    const userEmailIsRequired = features.ordering.mandatoryUserEmailForTakeawayPayment;
    const [vatIsValid, setVatIsValid] = useState(true);
    const [userBill, setUserBill] = useState(0); // Amount of the bill the user wants to pay
    const [tipValue, setTipValue] = useState(paymentDetails.tip);
    const [selectedTipOption, setSelectedTipOption] = useState(paymentDetails.selectedTip);
    const [tipModalIsOpen, setTipModalIsOpen] = useState(false);

    const submitBtnIsDisabled = (): boolean => userBill === 0 || !!formik.errors.email || !!formik.errors.vat || (userEmailIsRequired && !formik.values.email?.length);

    const getUserTotal = () => calculateUserTotal(roundDecimalsUp(userBill), tipValue);

    const handleAmountResult = (userBillAmount: number) => {
        setUserBill(userBillAmount);

        let tip: number;
        if (!userBillAmount || selectedTipOption.id === TipsOptions.empty().id) {
            tip = 0;
        } else if (selectedTipOption.id === TipsOptions.otherButton().id) {
            tip = tipValue;
        } else if (userBillAmount < SECOND_TIER_TIP) {
            tip = selectedTipOption.fisrtTierFixedTip;
            setTipValue(tip);
        } else if (userBillAmount >= SECOND_TIER_TIP && userBillAmount < LOW_AMOUNT_PAYMENT) {
            tip = selectedTipOption.secondTierFixedTip;
            setTipValue(tip);
        } else {
            tip = calculateTip(userBillAmount, selectedTipOption.percentage);
            setTipValue(tip);
        }
    };

    const formik = useFormik({
        initialValues: {
            email: userEmail || paymentDetails.email || "",
            vat: userVat || paymentDetails.vatNumber || "",
        },
        validationSchema: Yup.object({
            email: userEmailIsRequired ? 
                Yup.string()
                    .email(t("form.emailValidation"))
                    .required(t("form.requiredField"))
                :
                Yup.string()
                    .email(t("form.emailValidation")),
        }),
        onSubmit: async (values) => {
            const order = await cartSession.submit();
            const newPaymentDetails: PaymentDetails = {
                total: getUserTotal(),
                amount: roundDecimalsUp(userBill),
                tip: tipValue,
                email: values.email,
                vatNumber: formatToCleanString(values.vat),
                additionalData: {
                    orderId: order.id,
                    scheduledDate: cartSession.scheduledDate,
                },
                selectedTip: selectedTipOption,
            };
            browserStorageService.savePaymentDivision(JSON.stringify(paymentDivision));
            browserStorageService.savePaymentDetails(JSON.stringify(newPaymentDetails));
            dispatch(setPaymentDetails(newPaymentDetails));

            appNavigation.goTo(urlBuilder => urlBuilder.payment.PaymentMethodsUrl());
            return;
        }
    });

    const onPayClick = () => {
        if(tipValue > 0 || features.ordering.enforceTip == false) {
            formik.handleSubmit();
            return;
        }

        setTipModalIsOpen(true);
    }

    const getFooter = () => {
        return <ButtonsSection>
            {
                    <input
                    {...qaTag('pay')}
                    type="button"
                    onClick={onPayClick}
                    value={
                        userBill > 0
                            ? `${t("pay.pay")} ${toFormattedPrice(getUserTotal(), "€")}`
                            : t("pay.pay")
                    }
                    className={`primary-button ${submitBtnIsDisabled() ? "disabled" : ""}`}
                />
            }
            {undefined}
        </ButtonsSection>
    }

    return <Page title={t("pay.title")} footer={getFooter()}>
        <section className="pay" style={{marginBottom: 0}}>
            <form>
                <PayTotalCart loadUserBill={handleAmountResult} />

                <TipSection
                    userBill={userBill}
                    setTipResult={setTipValue}
                    setTipChoice={(tipOption: TipsOptionsConfiguration) => setSelectedTipOption(tipOption)}
                    selectedTip={selectedTipOption}
                />
                <div className="pay__receipt">
                    {
                        !tipModalIsOpen && 
                        !userAuth && 
                        mastercard.isAvailable && 
                        fees.isLoading == false && 
                        mastercard.minEligibleAmount <= fees.getAmountWithFee(getUserTotal(), ChargeMethod.CreditCard) 
                        ?
                            <div className="pay__email">
                                <label htmlFor="email">{t("form.invoiceEmail")} ({userEmailIsRequired ? t("form.mandatory") : t("optional")})</label>
                                <Tooltip PopperProps={{className: "Mastercard MuiTooltip-popper MuiTooltip-popperArrow"}} title={t("mastercardCampaign.enterEmailToBeElligible")} placement="top" arrow open>
                                    <input type="email" id="email" name="email" placeholder={t("form.emailPlaceholder")} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                </Tooltip>
                                {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                            </div>
                        :
                            <div className="pay__email">
                                <label htmlFor="email">{t("form.invoiceEmail")} ({userEmailIsRequired ? t("form.mandatory") : t("optional")})</label>
                                <input type="email" id="email" name="email" placeholder={t("form.emailPlaceholder")} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {formik.touched.email && formik.errors.email && <Alert message={formik.errors.email} />}
                            </div>
                    }
                    <div className="pay__nif mt-5">
                        <label htmlFor="vat">{t("form.vat")} ({t("optional")})</label>
                        <input type="tel" id="vat" name="vat" placeholder={t("form.vatPlaceholder")}
                            value={formik.values.vat} onChange={formik.handleChange}
                            onBlur={(e: React.FocusEvent) => {
                                setVatIsValid(vatValidation(formik.values.vat));
                                formik.handleBlur(e);
                        }} />
                        {formik.touched.vat && !vatIsValid && <Alert message={t("form.vatValidation") } />}
                    </div>
                </div>
            </form>
        </section>
        <Dialog
                onClose={() => setTipModalIsOpen(false)}
                isOpen={tipModalIsOpen}
            >
            <div className="container" style={{ paddingTop: "1.75rem", paddingBottom: "1.75rem" }}>
                <div className="modal__header">
                    <h3>{t("paymentMethods.tipRecall")}</h3>
                    <div className="close-icon" onClick={() => setTipModalIsOpen(false)}>
                        <CloseIcon />
                    </div>
                </div>

                <p className="mb-5">{t("paymentMethods.tipRecallDescription")}</p>

                {
                    tipValue == 0 &&
                    <button type="button" className="secondary-button" onClick={() => formik.handleSubmit()}>{t("paymentMethods.noTip")}</button>
                }
                
                <br/>

                <TipSection
                    userBill={userBill}
                    setTipResult={setTipValue}
                    setTipChoice={(tipOption: TipsOptionsConfiguration) => setSelectedTipOption(tipOption)}
                    selectedTip={selectedTipOption}
                />

                <button
                    {...qaTag('continue-to-payment-method')}
                    type="button"
                    className={`primary-button mt-5 ${tipValue == 0 && "primary-button--inactive"}`}
                    onClick={() => formik.handleSubmit()}>{t("paymentMethods.continue")}
                </button>
            </div>
        </Dialog>
    </Page>
};
export default OrderCheckoutPage;
import Dialog from "../Shared/Dialog";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { IFeatured, useFeaturedsQuery } from "../../app/hooks/featured/useFeaturedsQuery";
import TabOptions from "../Shared/TabOptions";
import Gallery from "../../pages/PhysicalMenu/Gallery";
import { CloseIcon } from "../svgs/CloseIcon";

const FeaturedDialog = () => {
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    const featuredsQuery = useFeaturedsQuery(qrCodeId);
    const [state, setState] = useState({
        isOpen: false,
        selectedTab: undefined as (IFeatured | undefined),
    })

    useEffect(() => {
        if(featuredsQuery.isFirstLoading || featuredsQuery.data.length == 0){
            return;
        }
        
        const timeout = setTimeout(() => setState(s => ({
            ...s,
            selectedTab: featuredsQuery.data[0],
            isOpen: true,
        })), 2000);
        return () => clearTimeout(timeout);
    }, [featuredsQuery.isFirstLoading, featuredsQuery.data])
    
    return (
        <Dialog isOpen={state.isOpen} onClose={() => setState(s => ({...s, isOpen: false}))} animationDurationMilliseconds={1000}>
            {
                featuredsQuery.data.length > 0 && 
                <div className="container" style={{display: "flex", flexDirection: "column", flexGrow: 1, height: "auto", overflowY: "hidden"}}>
                    <div className="modal__header" style={{alignItems: "baseline", marginBottom: 0}}>
                        <TabOptions tabs={featuredsQuery.data} 
                                    selectedTab={state.selectedTab} 
                                    onTabSelected={(v) => setState(s => ({...s, selectedTab: v}))}
                                    getKey={t => t.name}
                                    getValue={t => t.name}
                        />
                        <div className="close-icon" onClick={() => setState(s => ({...s, isOpen: false}))}>
                            <CloseIcon />
                        </div>
                    </div>
                    <section className="menu__container" style={{flexGrow: 1, overflowY: "auto"}}>
                        {featuredsQuery.data.map((m, index) => m == state.selectedTab && <Gallery images={m.pages.map(p => ({Page: p.page, Url: p.url }))} menuIndex={index} key={index}/>)}
                    </section>
                </div>
            }
        </Dialog>
    )
}
export default FeaturedDialog;
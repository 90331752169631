import { useEffect, useState } from "react"
import { Query, QueryClient, useQueryClient } from "react-query";
import { EntityType, getIdFromKey, getKey } from "./EntityType";
import { ConnectionState, useWebEvents } from "../webevents/useWebEvents";
import { useAppSelector } from "../../hooks";
import { IMerchantListener } from "../webevents/listeners/IMerchantListener";
import { IMenuItemListener } from "../webevents/listeners/IMenuItemListener";
import { POLLING_FALLBACK_INTERVAL_MILLISECONDS } from "../../constants";
import { IQrCodeListener } from "../webevents/listeners/IQrCodeListener";
import { IDeGrazieListener } from "../webevents/listeners/IDeGrazieListener";
import { IChargeListener } from "../webevents/listeners/IChargeListener";
import { InvoiceType } from "../../services/api/contracts/models/Invoice";

const containsId = (query: Query, key: string): boolean => {
    if(query.meta == undefined) {
        return false;
    }

    const ids = query.meta["ids"] as (Set<string> | undefined);
    if(ids == undefined) {
        return false;
    }
    
    return ids.has(key);
}

const queryMatches = (q: Query, type: EntityType, id?: string): boolean => {
    if(containsId(q, getKey(type, id))) {
        return true;
    }

    if(q.meta == undefined) {
        return false;
    }

    const onAny = q.meta.refreshOnAnyUpdate as (boolean | undefined);
    if(onAny == undefined || onAny == false) {
        return false;
    }

    return q.queryKey.includes(getKey(type));
}

export const invalidateQuery = (query: QueryClient, type: EntityType, id?: string): Promise<void> => {
    console.debug(`Event ${EntityType[type]} of Id ${id} received!`)
    return query.invalidateQueries({ 
        predicate: (q) => queryMatches(q, type, id),
    });
}

const chargeListeners = new Map<string, IChargeListener>();
export const QueryInvalidator = () => {
    const queryClient = useQueryClient();
    const [ webEventClient, connectionState ] = useWebEvents();
    const merchantId = useAppSelector(state => state.merchant.merchantId);
    const qrCodeId = useAppSelector(state => state.merchant.qrCodeId);

    useEffect(() => {
        const onFocus = () => queryClient.invalidateQueries();
        window.addEventListener("focus", onFocus)
        return () => window.removeEventListener("focus", onFocus);
    }, [queryClient])

    useEffect(() => {
        const listener: IDeGrazieListener = {
            onMasterCardCampaignChanged: () => invalidateQuery(queryClient, EntityType.Mastercard),
        }
        webEventClient.addDeGrazieListener(listener);
        return () => webEventClient.removeDeGrazieListener(listener);
    }, [queryClient, webEventClient])

    useEffect(() => {
        if(webEventClient == undefined || !merchantId) {
            return;
        } 

        const listener: IMerchantListener = {
            merchantId: merchantId,
            onMerchantChanged: (evt) => invalidateQuery(queryClient, EntityType.Merchant, evt.merchantId),
            onMerchantConnectionChangedEvent: (evt) => invalidateQuery(queryClient, EntityType.QrCodeSession, qrCodeId),
        }
        webEventClient.addMerchantListener(listener);
        return () => webEventClient.removeMerchantListener(listener);
    }, [queryClient, webEventClient, merchantId, qrCodeId])

    useEffect(() => {
        if(webEventClient == undefined || !qrCodeId || !merchantId) {
            return;
        } 

        const listener: IQrCodeListener = {
            qrCodeId: qrCodeId,
            onQrCodeChangedEvent: (evt) => invalidateQuery(queryClient, EntityType.Merchant, merchantId),

            onSessionAddedEvent: (evt) => invalidateQuery(queryClient, EntityType.QrCodeSession, evt.qrCodeId),
            onSessionChangedEvent: (evt) => invalidateQuery(queryClient, EntityType.QrCodeSession, evt.qrCodeId),

            onOrderChangedEvent: (evt) => invalidateQuery(queryClient, EntityType.Order, evt.orderId),
            onOrderCommitedEvent: (evt) => invalidateQuery(queryClient, EntityType.Order, evt.orderId),

            onOrderFieldAdded: (evt) =>  invalidateQuery(queryClient, EntityType.OrderField, evt.id),
            onOrderFieldChanged: (evt) =>  invalidateQuery(queryClient, EntityType.OrderField, evt.id),
            onOrderFieldDeleted: (evt) =>  invalidateQuery(queryClient, EntityType.OrderField, evt.id),
        }
        webEventClient.addQrCodeListener(listener);
        return () => webEventClient.removeQrCodeListener(listener);
    }, [queryClient, webEventClient, qrCodeId, merchantId])

    useEffect(() => {
        if(webEventClient == undefined) {
            return;
        }

        const listener: IMenuItemListener = {
            merchantId: merchantId,
            onMenuItemChanged: (evt) => invalidateQuery(queryClient, EntityType.DigitalMenuItem, evt.itemId),
            onMenuItemAdded: (evt) => invalidateQuery(queryClient, EntityType.DigitalMenuItem, evt.itemId),
            onMenuItemDeleted: (evt) => invalidateQuery(queryClient, EntityType.DigitalMenuItem, evt.itemId),
            onMenuItemAvailabilityChanged: (evt) => invalidateQuery(queryClient, EntityType.DigitalMenuItem, evt.itemId),
        }
        webEventClient.addMenuItemListener(listener);
        return () => webEventClient.removeMenuItemListener(listener);
    }, [queryClient, webEventClient, merchantId])

    //Fallback in case the sockets are down
    useEffect(() => {
        if(connectionState == ConnectionState.Connected) {
            return;
        }

        const interval = setInterval(() => queryClient.invalidateQueries(), POLLING_FALLBACK_INTERVAL_MILLISECONDS);
        return () => clearInterval(interval);
    }, [connectionState, queryClient])


    useEffect(() => {
        const queryCache = queryClient.getQueryCache();
        const unsubscribe = queryCache.subscribe((event) => {
            if(event == undefined) {
                return;
            }
            
            if(queryMatches(event.query, EntityType.Charge)) {
                const allRelatedQueries = queryCache.findAll({
                    predicate: q => queryMatches(q, EntityType.Charge),
                });
                const idsIndexer = allRelatedQueries.map(e => e.meta!["ids"] as Set<string>).reduce((r, e) => {
                    for(const key of e) {
                        if(r.has(key)) {
                            continue;
                        }
                        r.add(key);
                    }
                    return r;
                }, new Set<string>());

                const entriesToDelete = new Map<string, IChargeListener>(chargeListeners);
                const entriesToAdd = new Map<string, IChargeListener>();
                const entriesToKeep = new Map<string, IChargeListener>();
                for(const key of idsIndexer) {
                    const id = getIdFromKey(EntityType.Charge, key);

                    if(entriesToKeep.has(id)) {
                        continue
                    }

                    if(entriesToAdd.has(id)) {
                        continue
                    }

                    const oldEntry = entriesToDelete.get(id);
                    if(oldEntry != undefined) {
                        entriesToDelete.delete(id);
                        entriesToKeep.set(id, oldEntry);
                        continue;
                    }

                    const listener: IChargeListener = {
                        chargeId: id,

                        onChargeStatusChangedEvent: (evt) => invalidateQuery(queryClient, EntityType.Charge, evt.chargeId),
                        onChargeSyncedEvent: (evt) => invalidateQuery(queryClient, EntityType.Charge, evt.chargeId),

                        onInvoiceAddedEvent: (evt) => invalidateQuery(queryClient, EntityType.Invoice, `${evt.chargeId}-${InvoiceType[evt.type]}`),
                        onInvoiceChangedEvent: (evt) => invalidateQuery(queryClient, EntityType.Invoice, `${evt.chargeId}-${InvoiceType[evt.type]}`),
                    }
                    entriesToAdd.set(id, listener);
                    webEventClient.addChargeListener(listener);
                }

                for(const [id] of entriesToDelete) {
                    chargeListeners.delete(id);
                }

                for(const [id, l] of entriesToAdd) {
                    chargeListeners.set(id, l);
                }
            }
        });

        return () => unsubscribe();
    }, [queryClient])

    useEffect(() => {
        for(const [, l] of chargeListeners) {
            webEventClient.addChargeListener(l);
        }
        return () => {
            for(const [, l] of chargeListeners) {
                webEventClient.removeChargeListener(l);
            }
        }
    }, [webEventClient, connectionState])

    return <></>
}
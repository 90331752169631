import { OrderField } from "../../services/api/contracts/models/OrderField";
import { useWeb10API } from "../../services/api/Web10Api/Web10API";
import { EntityType } from "../query/EntityType";
import { QueryResult } from "../query/QueryResult";
import { IResponse, useQueryable } from "../query/useQueryable";

interface Request {
    readonly qrCodeId: string;
    readonly languageIso: string;
}
export const useOrderFieldsQuery = (request: Request | undefined): QueryResult<OrderField[]> => {
    const api = useWeb10API();

    const query = useQueryable<Request, OrderField, IResponse<OrderField>>({
        queryName: "useOrderFieldsQuery",
        entityType: EntityType.OrderField,
        getId: (item: OrderField) => item.id,
        request: request,
        query: request => api.orderField.Get(request),
        refreshOnAnyUpdate: true,
    })
    
    return query;
}